import React, { useState, useEffect } from 'react';
import axios from 'axios';

const Home = () => {
  const [cardId, setCardId] = useState('');
  const [card, setCard] = useState(null);

  useEffect(() => {
    if (window.adsbygoogle) {
      window.adsbygoogle.push({});
    }
  }, []);

  const handleSearch = async (event) => {
    event.preventDefault();

    try {
      const response = await axios.get(`https://us-central1-cardsbr-27357.cloudfunctions.net/api/card/${cardId}`);
      setCard(response.data);
    } catch (error) {
      alert('Card Não Encontrado');
      setCard(null);
    }
  };

  return (
    <div style={{ position: 'relative', minHeight: '100vh' }}>
      <header className="d-flex justify-content-between align-items-center p-3 mb-3 border-bottom">
        <img src="./img/logo.png" alt="Logo" style={{ height: '50px' }} />
        <h1>TCG Brasil</h1>
      </header>

      <div className="card mb-3">
        <div className="card-body">
          <h5 className="card-title">Buscar Card</h5>
          <form onSubmit={handleSearch}>
            <div className="form-group">
              <label htmlFor="cardId">Numero do Card</label>
              <input
                type="text"
                className="form-control"
                id="cardId"
                value={cardId}
                onChange={(e) => setCardId(e.target.value.toUpperCase())}
                required
              />
            </div>
            <button type="submit" className="btn btn-primary mt-2">Buscar</button>
          </form>
        </div>
      </div>

      {card && (
        <div className="card">
          <div className="card-body">
            <center><h3 className="card-title">{card.name}</h3></center>
            <ul className="list-group list-group-flush">
              <li className="list-group-item"><strong>Numero#:</strong> {card.card_id}</li>
              <li className="list-group-item"><strong>Tipo:</strong> {card.type}</li>
              <li className="list-group-item"><strong>Categoria:</strong> {card.card_category}</li>
              <li className="list-group-item"><strong>Efeito:</strong> {card.effect}</li>
              <li className="list-group-item"><strong>Produto:</strong> {card.product}</li>
              <li className="list-group-item"><strong>Cor:</strong> {card.color}</li>
              <li className="list-group-item"><strong>Raridade:</strong> {card.rarity}</li>
              <li className="list-group-item"><strong>Vida:</strong> {card.life}</li>
              <li className="list-group-item"><strong>Poder:</strong> {card.power}</li>
              <li className="list-group-item"><strong>Atributo:</strong> {card.attribute}</li>
              <li className="list-group-item"><strong>Counter:</strong> {card.counter}</li>
              <li className="list-group-item"><strong>Trigger:</strong> {card.trigger}</li>
            </ul>
            <center>
            <img src={card.image_url} alt={card.name} style={{ width: '300px', height: '419px' }} />
            </center>
            <center><button onClick={() => setCard(null)} className="btn btn-secondary mt-3">Voltar</button></center>
          </div>
        </div>
      )}
    </div>
  );
};

export default Home;